import { useState } from "react"
import styled from "styled-components"
import Image from "gatsby-image"

import { COLORS, LAYOUT } from "src/layout/constants"
import LinkedinIcon from "src/svg/linkedin.svg"
import MailIcon from "src/svg/mail.svg"
import PlusIcon from "src/svg/plus.svg"
import MinusIcon from "src/svg/minus.svg"

const Box = styled.div`
  flex: 0 0 calc(33.33% - ${LAYOUT.margin * 2}px);
  margin: ${LAYOUT.margin}px;
  position: relative;
  .gatsby-image-wrapper {
    border: 3px solid ${COLORS.sherpa};
    border-radius: 50px;
    background-color: ${COLORS.sherpa};
    img {
      border-radius: 50px;
      filter: grayscale() contrast(150%);
      mix-blend-mode: screen;
    }
  }
  h4,
  h5 {
    color: ${COLORS.pink};
    margin: 5px 0;
  }
  @media (max-width: 799px) {
    h5 {
      font-size: 1em;
    }
  }
`
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
`
const Links = styled.div`
  a {
    margin-right: 4px;
    svg {
      height: 16px;
    }
  }
`
const Text = styled.p`
  white-space: pre-line;

  text-align: left;
  &:first-of-type {
    margin-top: 2px;
  }
  &:last-of-type {
    margin-bottom: 5px;
  }
  @media (min-width: 800px) {
    font-size: 0.8em;
  }
`
const Toggler = styled.button`
  border-top: 1px solid ${COLORS.blue};
  padding: 8px 20px 5px 0;
  svg {
    width: 24px;
    height: 24px;
  }
  @media (max-width: 799px) {
    padding: 10px;
    margin-top: 5px;
  }
`

interface Props {
  mate: Teammate
}

const TeammateBox = ({ mate: { photo, linkedin, email, name, job, text1, text2 } }: Props) => {
  const [open, setOpen] = useState(false)

  return (
    <Box>
      <Image fixed={photo.fixed} />
      <Overlay />
      <h4>{name}</h4>
      <h5>{job}</h5>
      <Links>
        <a href={linkedin} target="_blank" rel="noopener" title="LinkedIn">
          <LinkedinIcon />
        </a>
        <a href={`mailto:${email}`} title="E-mail">
          <MailIcon />
        </a>
      </Links>
      <Text>{text1.text1}</Text>
      {open && <Text>{text2.text2}</Text>}
      <Toggler onClick={() => setOpen(!open)} title="Lire la suite">
        {open ? <MinusIcon /> : <PlusIcon />}
      </Toggler>
    </Box>
  )
}

export default TeammateBox
